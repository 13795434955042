<script setup>
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import ErrorLine from '../Invoices/Edit/ErrorLine.vue';

// Components
import Button from '@/Components/Button/Button.vue';
import ButtonSmall from '@/Components/Button/Small.vue';
import DataCard from '@/Components/DataCard.vue';
import DataTableLine from '@/Components/DataTableLine.vue';
import Money from '@/Components/Input/Money.vue';
import Number from '@/Components/Input/Number.vue';
import Table from '@/Components/Table/Table.vue';

// Partials
import StatusBar from '@/Components/Invoice/StatusBar.vue';
import Title from '@/Components/Title/Title.vue';

// Icons
import pdf from '@i/pdf-icon-white.svg';
import save from '@i/save-icon-white.svg';

const { t } = useI18n();

const route = inject('route');
const props = defineProps({
    purchase_invoice: Object,
    vatRateOptions: Object,
});

const form = useForm({
    ...props.purchase_invoice,
    purchase_invoice_lines: props.purchase_invoice.purchase_invoice_lines.sort(
        (purchase_invoice_line) => purchase_invoice_line.type == 'manual'
    ),
});

const addEmptyLine = () => {
    form.purchase_invoice_lines.push({
        id: (Math.random() + 1).toString(36),
        type: 'manual',
        description: '',
        quantity: 1,
        unit_price: 0,
        vat_rate: 21,
    });
};
const removeLine = (i) => form.purchase_invoice_lines.splice(i, 1);

const tableSettings = [
    { label: t('Name'), width: '35%', cell: 'Bold' },
    { label: t('Quantity'), width: '15%' },
    { label: t('Unit price'), width: '15%' },
    { label: t('VAT'), width: '15%' },
    { label: t('Total'), width: '15%', cell: 'Bold' },
];

const tableData = computed(() =>
    form.purchase_invoice_lines
        .filter((line) => line.type != 'manual')
        .map((line) => {
            return {
                id: line.id,
                platformCosts: line.type,
                rowData: [
                    line.description,
                    line.quantity,
                    '€ ' + line.unit_price / 100,
                    line.vat_rate + '%',
                    '€ ' + line.amount,
                ],
            };
        })
);

const lengthCurrentLines = computed(() => tableData.value.length);

const newLines = computed(() => form.purchase_invoice_lines.filter((line) => line.type == 'manual'));

const sendForm = () => {
    form.put(route('staff.purchase-invoices.update', { purchase_invoice: props.purchase_invoice.id }), {
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <StaffLayout :title="$t('Purchase invoice')">
        <Title :title="purchase_invoice.number"> </Title>
        <div class="flex items-end gap-8">
            <DataCard class="mt-6">
                <p class="font-bold">{{ purchase_invoice.user.display_name }}</p>
                <p>
                    {{ purchase_invoice.user.company.address.street_name }}
                    {{ purchase_invoice.user.company.address.street_number
                    }}{{ purchase_invoice.user.company.address.street_number_addition }}<br />
                    {{ purchase_invoice.user.company.address.postcode }},
                    {{ purchase_invoice.user.company.address.city }}
                </p>
            </DataCard>

            <DataCard class="mt-6">
                <p v-if="purchase_invoice.user.phone_number" class="m-0">{{ purchase_invoice.user.phone_number }}</p>
                <p v-if="purchase_invoice.user.email" class="m-0">
                    {{ purchase_invoice.user.email }}
                </p>
            </DataCard>
        </div>

        <StatusBar :invoice="purchase_invoice" />

        <Table :simpleLayout="true" :tableSettings="tableSettings" :data="tableData">
            <template #extraTableBody>
                <tbody class="text-base text-blue" v-for="(newline, i) in newLines" :key="i">
                    <tr>
                        <td class="pr-6">
                            <FormKit
                                type="text"
                                name="description"
                                v-model="
                                    form.purchase_invoice_lines[
                                        form.purchase_invoice_lines.findIndex((line) => line.id == newline.id)
                                    ].description
                                "
                            />
                        </td>

                        <td class="pr-6">
                            <Number
                                v-model:value="
                                    form.purchase_invoice_lines[
                                        form.purchase_invoice_lines.findIndex((line) => line.id == newline.id)
                                    ].quantity
                                "
                            />
                        </td>

                        <td class="pr-6">
                            <Money
                                v-model:value="
                                    form.purchase_invoice_lines[
                                        form.purchase_invoice_lines.findIndex((line) => line.id == newline.id)
                                    ].unit_price
                                "
                                class="border-gray-medium"
                            />
                        </td>

                        <td class="pr-6">
                            <FormKit
                                type="select"
                                select-icon="down"
                                name="vat_rate"
                                v-model="
                                    form.purchase_invoice_lines[
                                        form.purchase_invoice_lines.findIndex((line) => line.id == newline.id)
                                    ].vat_rate
                                "
                                :options="vatRateOptions"
                            />
                        </td>

                        <td class="relative py-2">
                            <Money
                                disabled
                                :value="
                                    form.purchase_invoice_lines[
                                        form.purchase_invoice_lines.findIndex((line) => line.id == newline.id)
                                    ].unit_price *
                                    form.purchase_invoice_lines[
                                        form.purchase_invoice_lines.findIndex((line) => line.id == newline.id)
                                    ].quantity
                                "
                                :valueSymbol="'€'"
                            />

                            <button
                                class="absolute transition-opacity duration-300 -translate-y-1/2 top-1/2 right-4 hover:opacity-50"
                                @click="
                                    removeLine(form.purchase_invoice_lines.findIndex((line) => line.id == newline.id))
                                "
                            >
                                <img src="/icon/remove.svg" alt="" />
                            </button>
                        </td>
                    </tr>
                    <ErrorLine
                        v-if="form.errors.purchase_invoice_lines"
                        :errors="form.errors.purchase_invoice_lines[i + lengthCurrentLines]"
                    />
                </tbody>
            </template>
        </Table>

        <ButtonSmall orange @click="addEmptyLine" icon="/icon/add_48.svg" class="mt-2" id="add-line-button">
            <div class="flex items-center gap-2">
                <div class="w-full">+ {{ $t('Add {attribute}', { attribute: $t('line') }) }}</div>
            </div>
        </ButtonSmall>

        <div class="self-end w-2/5 mt-6">
            <DataTableLine>
                <p>{{ $t('Total ex. VAT') }}</p>
                <span class="font-bold"> € {{ purchase_invoice.total }}</span>
            </DataTableLine>
            <DataTableLine>
                <p>{{ $t('VAT') }}</p>
                <span class="font-bold"> € {{ purchase_invoice.vat }}</span>
            </DataTableLine>
            <DataTableLine>
                <p>{{ $t('Total incl. VAT') }}</p>
                <span class="font-bold"> € {{ purchase_invoice.total_including_vat }}</span>
            </DataTableLine>
        </div>

        <div class="flex self-end gap-6 mt-10 text-base">
            <Button
                orange
                @click="sendForm"
                :icon="save"
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :text="$t('Save')"
                class="text-base w-fit min-h-10"
            />

            <Button
                asA
                orange
                :icon="pdf"
                :href="route('staff.purchase-invoices.download', { id: purchase_invoice.id })"
                class="w-fit"
                :text="$t('Download')"
            />
        </div>
    </StaffLayout>
</template>
r
